import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";

import Spinner from "./components/Loader/Spinner";

import { Store } from "./store/context";

import * as serviceWorker from "./serviceWorker";

const App = lazy(() => import("./App"));

ReactDOM.render(
  <Store>
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
    </BrowserRouter>
  </Store>,
  document.getElementById("root")
);

serviceWorker.unregister();

import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
  spinner: {
    position: "absolute",
    width: "100%",
    top: 65
  }
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#b9f6ca"
  },
  barColorPrimary: {
    backgroundColor: "#00e676"
  }
})(LinearProgress);

const Spinner = () => {
  const classes = useStyles();

  return (
    <aside className={classes.spinner}>
      <ColorLinearProgress />
    </aside>
  );
};

export default Spinner;
